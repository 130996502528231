<script>
  import PaintbrushDivider from "./PaintbrushDivider.svelte";
</script>

<style>
  .jumbotron {
    /* background-color: #49494b; */
    color: lightgray;
    border-radius: 0px;
    margin: 0;
    width: 100%;
  }

  .jumbotron .container p .emphasized-text {
    color: #edf2f4;
  }

  .emphasized-text {
    font-weight: bold;
  }
</style>

<div class="jumbotron jumbotron-fluid p-2 bg-dark">
  <div class="container-fluid">
    <h1 class="display-4">Who We Are</h1>
    <p class="lead">
      Construction Painting Professionals, LLC is Denver's premier commercial
      and construction painting solution.
    </p>

    <PaintbrushDivider alignBrush="left" />

    <p>
      CPP is
      <span class="emphasized-text">deadline-driven,</span>
      <span class="emphasized-text">budget-aware</span>
      and
      <span class="emphasized-text">communicative</span>
      from beginning to end. We have the tools, knowledge, financial backing and
      experience necessary to handle any commercial project no matter the needs.
    </p>
  </div>
</div>

<style>
  .info-block {
    padding-top: 1rem;
    background-color: #bfbdc1;
  }

  .info-block h2 {
    text-align: center;
  }
</style>

<div class="col-md-6 info-block" style="background-color: #BFBDC1">
  <h2>What Sets Us Apart</h2>
  <br />
  <p style="text-align: center;">
    We strive to be the top choice for general contractors. Our company is built
    on ideals and practices that set us a cut above the rest.
  </p>
</div>

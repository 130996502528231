<style>
  .paintbrush-icon {
    height: 20px;
    width: 20px;
    transform: translateX(12px) translateY(-5px) scale(-1, 1);
    filter: invert(19%) sepia(75%) saturate(4319%) hue-rotate(344deg)
      brightness(77%) contrast(92%);
  }

  .paintbrush-container {
    text-align: right;
    margin-top: -5px;
    width: 100%;
    border-top: 1px solid #bf1e2e;
  }

  .paintbrush-container::after {
    bottom: 20px;
  }
</style>

<div class="paintbrush-container">
  <img class="paintbrush-icon" src="icons/paintbrush.svg" alt="Paintbrush" />
</div>
